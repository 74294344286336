import React from "react"
import { StaticQuery, graphql } from "gatsby"
import '../css/global.css'
import Hero from './../components/hero.js'
import Story from './../components/story.js'
import Layout from './../components/layout.js'
import HowItWorks from './../components/howitworks.js'
import EmailSignup from './../components/emailsignup.js'
import '../css/index.css'
import LeftExample from './../components/leftexample.js'
import RightExample from './../components/rightexample.js'
import CTAExample from './../components/ctaexample.js'
import SubmitIdea from './../components/submitidea.js'
import Footer from './../components/footer.js'
import { Helmet } from "react-helmet"
import Popup from './../components/popup.js'
import Faq from './../components/faq.js'
import PastChallenges from './../components/pastchallenges.js'

class Index extends React.Component {
render() {
  return (
    <div>
    <Popup/>
    <Layout>
    <StaticQuery
  query={graphql`
    query {
      fileName: file(relativePath: { eq: "preview.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `}
  render={data => (
    <Helmet title="Silly Goose Receipts">
    <meta name="description" content="Challenges to bring out your inner silly goose and get you enjoying the novel moments of life."/>
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Silly Goose Receipts" />
    <meta property="og:description" content="Challenges to bring out your inner silly goose and get you enjoying the novel moments of life." />
    <meta property='og:url' content="https://sillygoosereceipts.com" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="Silly Goose Receipts" />
    <meta name="twitter:description" content="Challenges to bring out your inner silly goose and get you enjoying the novel moments of life." />
    <meta name="image" content={data.fileName.childImageSharp.fluid.src} />
    <meta property="og:image" content={data.fileName.childImageSharp.fluid.src} />
    <meta name="twitter:image" content={data.fileName.childImageSharp.fluid.src} />
    </Helmet>
  )}
/>

    <Hero/>
    <div className="bodyContainer" style = {{zIndex:`3`, textAlign:`left`, overflow:`hidden`, display:`inline-block`, position:`relative`}}>
    <EmailSignup/>

    <HowItWorks/>
    <br/>
    <LeftExample bold="Challenge 082" message="Go around more than once in a revolving door."/>
    <br/>
    <RightExample bold="Challenge 148" message="Sleep at the opposite end of the bed."/>
    <br/>
    <CTAExample/>
    <Story/>
    </div>
    <br/>
    <br/>
    <br/>
    <PastChallenges/>
    <Faq/>
    <br/>
    <br/>
    <br/>
    <br/>
    <Footer/>
    </Layout>
    </div>
)
}
}

export default Index;
