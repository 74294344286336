import React, { PureComponent }from "react"
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';

export default class Stats extends PureComponent {

  constructor(){
    super();
    this.state = {
        data: [],
        data_loaded:false,
        started: false,
    }
}

  componentDidMount() {
      fetch("https://www.googleapis.com/download/storage/v1/b/something-new-site-data/o/num_signups.json?alt=media")
          .then( (response) => {
              return response.json() })
                  .then( (json) => {
                      this.setState({data: json, data_loaded:true})
                  })

  }
  render() {
    if (this.state.data_loaded) {
    return (
      <div style = {{fontFamily:`receipt`}}>
      <span>
      So you want to see what all the hubbub is about, eh? Well you came to the right place.<b>
      &nbsp;<CountUp duration = "20"
      end = {this.state.data.num_signups}>

      {({ countUpRef, start }) => (
    <span>
    <VisibilitySensor onChange={(isVisible) => {
                if (isVisible && !this.state.started) {
                  start()
                  this.setState({started:true});
                }}}
              delayedCall>
      <span ref={countUpRef} />
      </VisibilitySensor>
    </span>
  )}
  </CountUp>
  </b>&nbsp;people have already joined. Be sure to get in before it becomes a waitlist.
      </span>
      </div>

);}
else{return(
  <div style = {{fontFamily:`receipt, Sans-Serif`}}>
  So you want to see what all the hubbub is about, eh? Well you came to the right place.<br/><br/>
  <span>

  A lot of people have already joined. As this is self-funded, I am going to be switching to a waitlist pretty soon here.
  </span>
  </div>

);}
}
}
