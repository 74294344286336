import React, { PureComponent } from 'react';
import './../css/submitidea.css'
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";

var shareUrl = "https://sillygoosereceipts.com"
var quote = "Calling all silly geese."
export default class Counter extends PureComponent {

  state = {
    name: "",
    idea: "",
    message:"",
    location:"",
    success: false
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleAnother = event => {
    this.setState({success:false,name: "",
        idea: "",
        message:"",
        location:"",})
  }

  handleSubmit = event => {
    event.preventDefault()
      this.setState({message:""})
      if(this.state.idea.length>0)
        {
          fetch('/.netlify/functions/post_idea', {
            method: "POST",
            body: JSON.stringify({
              idea: this.state.idea,
              name: this.state.name,
              location: this.state.location
            })
          })
          this.setState({success:true})
        }
      else
        this.setState({message: "Please enter an idea"})
    };

  render() {
    return (
      <div className = "bodyElement" style={{marginTop:`3vh`, fontFamily:`receipt, Sans-Serif`}}>
      <div ref={this.refA}>
      <div id='submitidea'><br/><h2 style = {{fontFamily:`receipt, Sans-Serif`, color:`black`}}>Your idea</h2></div>
      Woah woah woah, you want to contribute your own idea and help shape the future of this project? That's pretty neat. <br/><br/>You can do that here. A lot of people already have, join them!
      <br/>
      <br/>
      <div className={this.state.success ? 'emailFadeOut' : 'emailFadeIn'}>
      <form onSubmit={this.handleSubmit} style = {{verticalAlign:`baseline`, width:`100%`}}>
          <input
            id="name"
            type="text"
            name="name"
            placeholder = "First Name"
            style = {{resize:`none`,color:`#355e3b`,fontFamily:`receipt`,width:`48%`, border:`2px solid #355e3b`, borderRadius:`0`, height:`2.5rem`, padding:`1rem`, marginBottom:`1rem`}}
            value={this.state.name}
            onChange={this.handleInputChange}
          />
          <input
            id="location"
            type="text"
            name="location"
            placeholder = "Location"
            style = {{resize:`none`,color:`#355e3b`,fontFamily:`receipt`,width:`48%`, border:`2px solid #355e3b`, borderRadius:`0`, height:`2.5rem`, padding:`1rem`, marginBottom:`1rem`, marginLeft:`4%`}}
            value={this.state.location}
            onChange={this.handleInputChange}
          />
          <textarea
            id="submitIdea"
            type="text"
            name="idea"
            placeholder = "Your idea (eg. Find art to spruce up your place.)"
            style = {{resize:`none`,color:`#355e3b`,fontFamily:`receipt`,width:`100%`, border:`2px solid #355e3b`, borderRadius:`0`, height:`5.5rem`, padding:`1rem`}}
            value={this.state.idea}
            onChange={this.handleInputChange}
          />


          <p style = {{fontFamily:`receipt, Sans-Serif`, color:`#FF0000`, fontSize:`small`, minHeight:`1rem`, marginBottom:`0`}}> {this.state.message}</p>
        <button type="submit" style = {{fontFamily:`receipt, Sans-Serif`, height:`2.5rem`, border:`2px solid #355e3b`, backgroundColor:`#355e3b`, color:`white`, width:`100%`, float:`right`}}>SUBMIT</button>
            <label class="ohnohoney" for="name"></label>
            <input class="ohnohoney" autocomplete="off" type="text" id="name" name="name" placeholder="Your name here" value={this.state.name} onChange={this.handleInputChange}/>

      </form>

      </div>
      <div className={this.state.success ? 'emailFadeIn' : 'emailFadeOut'} style={{backgroundColor:`#355e3b`, padding:`1rem`}}>
      <span style = {{fontFamily:`receipt, Sans-Serif`,color:`#FFFFFF`}}> <b>Thank you!!</b> <i>Know more amazing and thoughtful people like yourself?</i> <b>Share this with them.</b> </span>
      <div id = "shareholder" style = {{maxWidth:'600px', width:`100%`, marginTop:`1rem`}}>
      <FacebookShareButton
        url={shareUrl}
        quote={quote}
        style = {{marginRight:`10px`}}>
        <FacebookIcon
        size={40}
        iconFillColor={"#355e3b"}
        round />
        </FacebookShareButton>

        <LinkedinShareButton
        url={shareUrl}
        quote={quote}
        style = {{marginRight:`10px`}}>
        <LinkedinIcon
        size={40}
        iconFillColor={"#355e3b"}
        round />
        </LinkedinShareButton>

        <TwitterShareButton
        url={shareUrl}
        quote={quote}
        style = {{marginRight:`10px`}}>
        <TwitterIcon
        size={40}
        iconFillColor={"#355e3b"}
        round />
        </TwitterShareButton>

        <WhatsappShareButton
        url={shareUrl}
        quote={quote}
        style = {{marginRight:`10px`}}>
        <WhatsappIcon
        size={40}
        iconFillColor={"#355e3b"}
        round />
        </WhatsappShareButton>

        <RedditShareButton
        url={shareUrl}
        quote={quote}
        style = {{marginRight:`10px`}}>
        <RedditIcon
        size={40}
        iconFillColor={"#355e3b"}
        round />
        </RedditShareButton>
        </div>
        <button style = {{width:`100%`, color:`#355e3b`, background:`white`, marginTop:`1rem`}} onClick={this.handleAnother}> Submit another idea </button>
      </div>

      </div>
      </div>);}

}
