import React from "react"
import '../css/hero.css'
import ScrollIntoView from 'react-scroll-into-view'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from "gatsby"

class Hero extends React.Component {

  componentDidMount() {
    var changeClass;
    if (typeof window != 'undefined'){

      function change_class(){
        try{
          document.getElementById("receipt").className = "receiptShow";
          clearInterval(changeClass);}
        catch(e) {}
      }

changeClass = window.setInterval(function(){
       /// call your function here
      change_class();
    }, 50);

  }
}
render() {
  return (
    <div id = "container">
    <div id = "receipt" className = "receiptHide">
    <div id = "receiptText">

    <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "goose_dark.png" }) {
              childImageSharp {
                fluid(maxWidth:150) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        `}
        render={data => (
          <Img style = {{maxWidth:`75px`, margin:`auto`}}fluid = {data.file.childImageSharp.fluid}/>
        )}
      />
    <br/>WELCOME TO <b>SILLY GOOSE RECEIPTS:</b>
    <br/>
    <br/><span style = {{fontSize:`medium`}}>A WEEKLY EMAIL DESIGNED TO GET YOU OUTSIDE THE ROUTINES OF LIFE.</span>
    <br/><br/>
    <b><ScrollIntoView style = {{color:`#0000EE`,cursor:`pointer`, fontSize:`medium`}}selector="#base2"><u>JOIN ADVENTUROUS SOULS LIKE YOURSELF AND SIGN UP TODAY</u></ScrollIntoView></b>
    <br/>
    </div>
    </div>
    <div id = "base1"/>
    <div id = "base2"/>
    <div style={{position:`absolute`, height:`600px`, width:`100%`, zIndex:`2`, background:`white`}}/>
    </div>
)
}
}

export default Hero;
