import React from "react"
import { Link } from "gatsby"
import Illustration from './illustration.js'
import ScrollIntoView from 'react-scroll-into-view'

class PastChallenges extends React.Component {
  constructor(){
    super();
    this.state = {
        data: [],
        data_loaded:false,
        num_shown: 3,
    }
    this.loadMore = this.loadMore.bind(this);
}

loadMore() {
   this.setState({num_shown: this.state.num_shown+3});
 }

  componentDidMount () {
    fetch("https://www.googleapis.com/download/storage/v1/b/something-new-site-data/o/challenges.json?alt=media")
        .then( (response) => {
            return response.json() })
                .then( (json) => {
                    this.setState({data: json.reverse(), data_loaded:true})
                })
}
render() {
  if (this.state.data_loaded) {
  return (
    <div className = "bodyElement" style = {{fontFamily:`receipt, Sans-Serif`}}>
    <h2 style = {{fontFamily:`receipt, Sans-Serif`, color:`black`}}>Past Challenges</h2>
    {this.state.data.slice(0,this.state.num_shown).map(item => <p><b>{item.Challenge}</b> - {item.Markdown} <span style = {{fontSize:`small`}}>({item.Date})</span></p>)}
    {this.state.num_shown < this.state.data.length ? <a style = {{color:`#0000EE`}} className = "loadmore" onClick={this.loadMore}><u>Load More</u></a>: null }
    </div>
)
}
else return(<div/>)
}

}

export default PastChallenges;
