import React from "react"
import { Link } from "gatsby"
import Illustration from './illustration.js'
import ScrollIntoView from 'react-scroll-into-view'

class Story extends React.Component {
  constructor(){
    super();
    this.state = {
        data: [],
        data_loaded:false,
    }
}
  componentDidMount () {
    fetch("https://www.googleapis.com/download/storage/v1/b/something-new-site-data/o/num_signups.json?alt=media")
        .then( (response) => {
            return response.json() })
                .then( (json) => {
                    this.setState({data: json, data_loaded:true})
                })
}
render() {
  if (this.state.data_loaded) {
  return (
    <div className = "bodyElement" style = {{fontFamily:`receipt, Sans-Serif`}}>
    <div><br/><h2 style = {{fontFamily:`receipt, Sans-Serif`, color:`black`}}>F.A.Q.</h2></div>
    <span id='faq'>
    <b>How often are the challenges sent out?</b><br/>
    You can expect about 4 per month.
    <br/><br/><b>How are the challenges picked?</b><br/>
    They are largely inspired by my own cycles and habits I am trying to break. <a href = "/your-idea" >Have an idea for a challenge? I'd love to hear from you.</a>
    <br/><br/><b>Do you still have space left to join?</b><br/>
    Yeah, a little. The last time I checked the signup count, we had <i>{this.state.data.num_signups}</i> signups, which means we have room for a couple more.<ScrollIntoView style = {{cursor:`pointer`, color:`#0000EE`}}selector="#base2"><u>Sign up here.</u></ScrollIntoView>
    </span>
    <div style={{textAlign:`center`}}>
    </div>
    <span>
    <br/>


    </span>
    </div>
)
}
else return(<div/>)
}

}

export default Story;
