import React from "react"
import { Link } from "gatsby"
import '../css/leftexample.css'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";

export default props =>
    <div id = "leftexcontainer" className = "bodyElement">
    <div id = "leftexbase1"/>
    <div id = "leftexbase2"/>
    <div id = "leftexreceipthider"/>
    <ScrollAnimation animateIn="slideInLeft" animateOnce="true">
    <div id = "leftexreceipt">
    <div id = "leftexreceiptText">
    <b>{props.bold}</b><br/> {props.message}<br/>
    </div>
    </div>
    </ScrollAnimation>
    </div>
