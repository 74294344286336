import React from "react"

class Animation extends React.Component {
  constructor(props) {
    super(props);
    this.state = { translation: 0 };
    this.updateAnimationState = this.updateAnimationState.bind(this);
  }

  componentDidMount() {
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  componentWillUnmount() {
    cancelAnimationFrame(this.rAF);
  }

  updateAnimationState() {
    if (this.state.translation>-89)
      this.setState(prevState => ({ translation: prevState.translation - 0.5 }));
    else if (Math.abs(-89.2-this.state.translation)<.0001){
      this.setState(prevState => ({ translation: 0 }));
    }
    else {
      this.setState(prevState => ({ translation: prevState.translation - 0.001 }));
    }
    this.rAF = requestAnimationFrame(this.updateAnimationState);
  }

  render() {
    return <Canvas translation={this.state.translation} />
  }
}

class Canvas extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
  }

  componentDidUpdate() {

    function canvas_arrow(context, fromx, fromy, tox, toy) {
    var headlen = 10; // length of head in pixels
    var dx = tox - fromx;
    var dy = toy - fromy;
    var angle = Math.atan2(dy, dx);
    context.moveTo(fromx, fromy);
    context.lineTo(tox, toy);
    context.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
    context.moveTo(tox, toy);
    context.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
  }

    const {translation} = this.props;
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width;
    const height = canvas.height;
    ctx.save();
    ctx.beginPath();
    ctx.clearRect(0, 0, width, height);

    //table top
    ctx.fillStyle = "#000000";
    ctx.beginPath();
    ctx.lineWidth = 5;
    ctx.ellipse(300, 180, 250, 150, 0, 0, 2 * Math.PI);
    ctx.stroke();

    //left leg
    ctx.beginPath();
    ctx.moveTo(175,310);
    ctx.lineTo(100, 550);
    ctx.stroke();

    //middle leg
    ctx.beginPath();
    ctx.moveTo(300,330);
    ctx.lineTo(300, 450);
    ctx.stroke();

    //right leg
    ctx.beginPath();
    ctx.moveTo(425,310);
    ctx.lineTo(500, 550);
    ctx.stroke();

    //parallel1
    ctx.beginPath();
    ctx.moveTo(330, 120);
    ctx.lineTo(430,120);
    ctx.lineTo(400, 240);
    ctx.lineTo(300, 240);
    ctx.closePath();
    ctx.fill();

    //receipt
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.rect(347, 140+translation, 60, 95);
    ctx.strokeStyle = "#000000";
    ctx.stroke();
    ctx.fillStyle = "#FFFFFF";
    ctx.fill();

    //receiptText
    ctx.fillStyle = "#000000";
    ctx.font = "10px Receipt";
    ctx.fillText("Here's", 357, 160+translation);
    ctx.fillText("a fun", 359, 180+translation);
    ctx.fillText("challenge", 347, 200+translation);
    ctx.fillText("for you", 352, 220+translation);

    //white hider
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.rect(395, 200, 60, 95);
    ctx.fillStyle = "#FFFFFF";
    ctx.fill();

    //parallel2
    ctx.fillStyle = "#000000";
    ctx.beginPath();
    ctx.moveTo(325, 140);
    ctx.lineTo(425,140);
    ctx.lineTo(395, 260);
    ctx.lineTo(295, 260);
    ctx.closePath();
    ctx.fill();

    //plantbase
    ctx.fillStyle = "#000000";
    ctx.beginPath();
    ctx.lineWidth = 3;
    ctx.ellipse(200, 160, 50, 30, Math.PI/1.4, 0, 2 * Math.PI);
    ctx.stroke();

    //arrow
    ctx.beginPath();
    canvas_arrow(ctx, 80, 30, 140, 100);
    ctx.font = "15px Receipt";
    ctx.fillStyle = "#355e3b";
    ctx.fillText("My funky house plant named Tim", 0, 12);
    ctx.stroke();

    //plant1
    ctx.fillStyle = "#355e3b";
    ctx.beginPath();
    ctx.moveTo(180, 160);
    ctx.bezierCurveTo(210, 80, 200, 150, 160, 100);
    ctx.bezierCurveTo(150, 160, 200, 50, 220, 150);
    ctx.fill();


    ctx.restore();
  }

  render() {
    return <canvas style = {{maxWidth:`600px`, width:`100%`}} width={600} height={550} ref={this.canvasRef}></canvas>;
  }
}

export default Animation
