import React, { PureComponent } from 'react';
import './../css/popup.css'
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import Cookies from 'js-cookie';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
var shareUrl = "https://sillygoosereceipts.com"
var quote = "Calling all silly geese."
var validator = require("./../js/validate.js");
var modal;
var close;
export default class Counter extends PureComponent {

  state = {
    email: "",
    name: "",
    message: "",
    success: false,
    shown: false,
    already_signed_up: Cookies.get('email'),
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = event => {
    event.preventDefault()
    if (validator.validate(this.state.email, this.state.name)) {
      this.setState({message:""})
      fetch('/.netlify/functions/signup', {
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
        })
      }).then((response) => {
  })
      this.setState({success:true});
      Cookies.set('email', 'true');
      trackCustomEvent({
          category: "Email Signup",
          action: "Submit",
          label: "Popup"
        });
    }
    else {
      if(this.state.name.length>0)
        this.setState({message: "No spam please!"})
      else
        this.setState({message: "Oops! Doesn't look like that is a valid email. Try again!"})
    };

  }

  componentDidMount() {
    this.timer = setInterval(
        () => (this.setState({already_signed_up:Cookies.get('email')})),
        39500)
    this.timer2 = setInterval(
        () => this.setState({ shown: true }),
        40000
    )
    this.timer3 = setInterval(
        () => Cookies.set('email', 'shown'),
        41000
    )


    if (typeof window != 'undefined'){
      modal = document.getElementById("modal");
      close = document.getElementById("close");
      window.onclick = function(event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
        if (event.target == close){
          modal.style.display = "none";
        }
      }


    }
  }
render() {
  if (typeof this.state.already_signed_up !== 'undefined'){
  return(<div id = "modal"><div id = "close"/></div>)
}
else
  return(
<div id = "modal" className={this.state.shown ? 'modal' : 'invisible-modal'}>
<div className="popup">
<div id = "close" class="close" style={{position:`fixed`, fontSize:`40px`, top:`10px`, right:`10px`, color:`gray`}}>&times;</div>
<div className={this.state.success ? 'popupContentFadeOut' : 'popupContentFadeIn'}>
<StaticQuery
    query={graphql`
      query {
        file(relativePath: { eq: "thisistim.png" }) {
          childImageSharp {
            fluid(maxWidth:150) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `}
    render={data => (
      <Img style = {{maxWidth:`120px`, margin:`auto`, marginTop:`20px`}} fluid = {data.file.childImageSharp.fluid}/>
    )}
  />
<div style = {{fontFamily:`receipt, Sans-Serif`, color:`black`, padding:`10px`, textAlign:`center`}}> Don't disappoint Tim the Houseplant, sign up for Silly Goose Receipts! </div>
<form onSubmit={this.handleSubmit} style = {{display:`flex`, verticalAlign:`baseline`,paddingLeft:`20px`, paddingRight:`20px`}}>
    <input
      type="text"
      name="email"
      placeholder = "you@example.com"
      style = {{color:`#0000EE`,fontFamily:`receipt, Sans-Serif`,width:`80%`, border:`2px solid #0000EE`, borderRadius:`0`, height:`2.5rem`, paddingLeft:`1rem`}}
      value={this.state.email}
      onChange={this.handleInputChange}
    />

  <button type="submit" id = "signupjoin" style = {{fontFamily:`receipt`, fontSize:`x-small`,height:`2.5rem`, border:`2px solid #0000EE`, backgroundColor:`#0000EE`, color:`white`, width:`20%`}}>JOIN</button>
      <label class="ohnohoney" for="name"></label>
      <input class="ohnohoney" autocomplete="off" type="text" id="name" name="name" placeholder="Your name here" value={this.state.name} onChange={this.handleInputChange}/>
</form>
<div style = {{fontFamily:`receipt, Sans-Serif`, color:`#FF0000`, fontSize:`small`, minHeight:`3rem`, marginBottom:`0`, paddingLeft:`20px`, paddingRight:`20px`, paddingBottom:`20px`}}> {this.state.message}</div>
</div>

<div style = {{backgroundColor:`#0000EE`,padding:`2rem`}} className={this.state.success ? 'popupContentFadeIn' : 'popupContentFadeOut'}>
<p style = {{fontFamily:`receipt`,color:`#FFFFFF`}}> <b>This is going to be awesome.</b><br/><br/> You're officially a silly goose. Know any other lovely individuals like yourself? Share this with them. </p>
<div id = "shareholder-signup" style = {{maxWidth:'600px', width:`100%`, marginTop:`1rem`}}>
<FacebookShareButton
  url={shareUrl}
  quote={quote}
  style = {{marginRight:`10px`}}>
  <FacebookIcon
  size={40}
  iconFillColor={"#0000EE"}
  round />
  </FacebookShareButton>

  <LinkedinShareButton
  url={shareUrl}
  quote={quote}
  style = {{marginRight:`10px`}}>
  <LinkedinIcon
  size={40}
  iconFillColor={"#0000EE"}
  round />
  </LinkedinShareButton>

  <TwitterShareButton
  url={shareUrl}
  quote={quote}
  style = {{marginRight:`10px`}}>
  <TwitterIcon
  size={40}
  iconFillColor={"#0000EE"}
  round />
  </TwitterShareButton>

  <WhatsappShareButton
  url={shareUrl}
  quote={quote}
  style = {{marginRight:`10px`}}>
  <WhatsappIcon
  size={40}
  iconFillColor={"#0000EE"}
  round />
  </WhatsappShareButton>

  <RedditShareButton
  url={shareUrl}
  quote={quote}
  style = {{marginRight:`10px`}}>
  <RedditIcon
  size={40}
  iconFillColor={"#0000EE"}
  round />
  </RedditShareButton>

  <EmailShareButton
  url={shareUrl}
  quote={quote}
  style = {{marginRight:`10px`}}>
  <EmailIcon
  size={40}
  iconFillColor={"#0000EE"}
  round />
  </EmailShareButton>
  </div>
</div>

</div>

</div>
);
}
}
