import React from "react"
import '../css/leftexample.css'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css"
import ScrollIntoView from 'react-scroll-into-view'

export default props =>
    <div id = "leftexcontainer" className = "bodyElement">
    <div id = "leftexbase1"/>
    <div id = "leftexbase2"/>
    <div id = "leftexreceipthider"/>
    <ScrollAnimation animateIn="slideInLeft" animateOnce="true">
    <div id = "leftexreceipt">
    <div id = "leftexreceiptText">
    <b>Challenge ??? <br/><br/> <ScrollIntoView style = {{color:`#0000EE`, cursor:`pointer`}}selector="#base2"><u>SIGN UP HERE.</u></ScrollIntoView><br/></b>
    </div>
    </div>
    </ScrollAnimation>
    </div>
