import React from "react"
import { Link } from "gatsby"


class Story extends React.Component {
  componentDidMount () {
}
render() {
  return (
    <div className = "bodyElement">
    <div ><br/><h2 style = {{fontFamily:`receipt, Sans-Serif`, color:`black`}}>WHAT IS IT?</h2></div>
    <span id='story' style = {{fontFamily:`receipt, Sans-Serif`}}>
    <b>My deepest fear is monotony:</b> getting trapped in the everyday cycles of life without taking the time to appreciate the novelty around me.<br/><br/>
    <b><i>Silly Goose Receipts</i> is my take on alleviating that fear:</b> At random times in the month, I will send you an email with a challenge to get you outside of your routine, bringing out your inner silly goose.<br/><br/>
    </span>
    </div>
)
}
}

export default Story;
