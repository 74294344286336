import React from "react"
import { Link } from "gatsby"
import '../css/rightexample.css'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";


export default props =>
    <div id = "rightexcontainer" className = "bodyElement">
    <div id = "rightexreceipthider"/>
    <div id = "rightexbase1"/>
    <ScrollAnimation animateIn="slideInRight" animateOnce="true">
    <div id = "rightexreceipt">
    <div id = "rightexreceiptText">
    <b>{props.bold}</b><br/> {props.message}<br/>
    </div>
    </div>
    </ScrollAnimation>
    <div id = "rightexbase2"/>

    </div>
