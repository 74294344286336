import React from "react"
import { Link } from "gatsby"
import Illustration from './illustration.js'
import ScrollIntoView from 'react-scroll-into-view'
import './../css/story.css'

class Story extends React.Component {
  componentDidMount () {
}
render() {
  return (
    <div className = "bodyElement" style = {{fontFamily:`receipt, Sans-Serif`}}>
    <div><br/><h2 style = {{fontFamily:`receipt, Sans-Serif`, color:`black`}}>THE STORY</h2></div>
    <span id='story'>
    This project started when I realized I was getting stuck in habits too frequently...so I crafted different challenges to bring out my inner child and rebuild my curiosity for the world around me.<br/><br/>
    I set up a little receipt printer on my bedside table (conveniently next to my houseplant named Tim) and at random times throughout the week, it prints out a challenge: <i>my mission for that day.</i> <br/><br/>
    </span>
    <div style={{textAlign:`center`}}>
    <Illustration/>
    </div>
    <span>
    <br/>
    Sometimes it's a focus on mental health, sometimes a focus on social health, sometimes a focus on creative health...<br/><br/>

    But it is generally something that is in pursuit of growth.<br/><br/>

    <ScrollIntoView className = "cta" style = {{color:`white`,cursor:`pointer`, width:`100%`, maxWidth:`600px`, marginTop:`1.2rem`, backgroundColor:`#0000EE`, padding:`1rem`, textAlign:`center`}}selector="#base2"><b>SIGN UP FOR THE CHALLENGE HERE. THE WORLD NEEDS MORE SILLY GEESE!</b></ScrollIntoView>
    </span>
    </div>
)
}
}

export default Story;
